<template>
    <div class="color-selector">
      <div
        v-for="(color, index) in colors"
        :key="index"
        :class="color.value == selectedColor?'selected' : ''"
        :style="{ backgroundImage: `url(${color.value})` }"
        @click="selectColor(color.value)"
      ></div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      colors: {
        type: Array,
        required: true
      },
      value: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        selectedColor: this.value || null
      };
    },
    watch: {
    value(newVal) {
      this.selectedColor = newVal;
    },
    selectedColor(newVal) {
      this.$emit('selected-color', newVal); // 自定义事件名
    }
  },
    methods: {
      selectColor(colorValue) {
        this.selectedColor = colorValue;
        this.$emit('selected-color', colorValue); // 发送选中的颜色值给父组件
      }
    }
  };
  </script>
  
  <style scoped>
  .color-selector {
    display: flex;
    gap: 10px;
    margin-right: 10px;
  }
  
  .color-selector div {
    width: 38px;
    height: 38px;
    background-size: auto;
    background-position: 30% 30%;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .color-selector div.selected {
    width: 40px;
    height:40px;
    /* transform: translateX(5px) translateY(5px); */
  }
  
  .color-selector div::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    /* border: 2px solid white; */
    border-radius: 50%;
  }
  
  .color-selector div.selected::after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    transform: scale(0.8);
    border: 3px solid white;
    border-radius: 50%;
  }
  </style>