<template>
  <div class="container">
    <H2>印留时光3D商品定制化演示</H2>
    <!-- <div class="options">
      <label for="straps_band">选择背带颜色:</label>

      <select v-model="straps_band" id="straps_band" class="option">
        <option value="./3Ddressing/straps_band_black_base_color-keuqt.jpg">
          黑色带子
        </option>
        <option value="./3Ddressing/straps_band_brown_base_color-u4grtp.png">
          棕色带子
        </option>
        <option value="./3Ddressing/straps_band_red_base_color-gl499.jpg">
          红色带子
        </option>
        <option value="./3Ddressing/straps_band_yellow_base_color-285zpl.jpg">
          黄色带子
        </option>
      </select>
      <label for="logo">选择五金件:</label>
      <select id="logo" v-model="logo" class="option">
        <option value="./3Ddressing/black-1024-gafxfi.jpg">黑色</option>
        <option value="./3Ddressing/gray-1024-cr7rw.png">灰色</option>
        <option value="./3Ddressing/hardware_gold_base_color-rufdgp.jpg">
          金色硬件
        </option>
      </select>
      <label for="state">包包打开状态:</label>
      <select id="state" v-model="state" class="option">
        <option value="open">打开</option>
        <option value="closed">关闭</option>
      </select>
      <label for="bag_body">选择包主体颜色:</label>
      <select id="bag_body" v-model="bag_body" class="option">
        <option value="./3Ddressing/bag_body_black_base_color-dowtw5.jpg">
          黑色
        </option>
        <option value="./3Ddressing/bag_body_brown_base_color-b9olzd.png">
          棕色
        </option>
        <option value="./3Ddressing/bag_body_red_base_color-0omg4f.jpg">
          红色
        </option>
        <option value="./3Ddressing/bag_body_yellow_base_color-psmu5n.jpg">
          黄色
        </option>
      </select>
    </div> -->
    <div class="options">
      <div id="state" class="divbox" style="margin-right: 10px">
        <div
          @click="updateOpenstate('open')"
          :class="state == 'open' && 'select'"
        >
          打开包包
        </div>
        <div
          @click="updateOpenstate('closed')"
          :class="state == 'closed' && 'select'"
        >
          关闭包包
        </div>
      </div>
    </div>

    <div class="options">
      <div class="option">
        <label for="straps_band">选择背带颜色:</label>
        <ColorSelector
          :colors="strapsBandColors"
          :value="straps_band"
          @selected-color="updateStraps_band"
        />
      </div>
      <div class="option">
        <label for="logo">选择五金件:</label>
        <ColorSelector
          :colors="logoColors"
          :value="logo"
          @selected-color="updateLogo"
        />
      </div>

      <div class="option">
        <label for="bag_body">选择包主体颜色:</label>
        <ColorSelector
          :colors="bagBodyColors"
          :value="bag_body"
          @selected-color="updateMaterialTexture"
        />
      </div>
    </div>

    <div v-if="loadingProgress < 100" class="progress-bar-container">
      <div class="progress-bar" :style="{ width: progressWidth + '%' }">
        {{ loadingProgress }}%
      </div>
    </div>
    <div id="canvasbox" style="flex-grow: 1; width: 100%; position: relative">
      <canvas ref="modelCanvas" class="model-canvas"></canvas>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import ColorSelector from "./ColorSelector.vue";
const scene = new THREE.Scene();
var renderer = null;
export default {
  components: {
    ColorSelector,
  },
  data() {
    return {
      bag_body: "./3Ddressing/bag_body_brown_base_color-b9olzd.png",
      state: "closed",
      straps_band: "./3Ddressing/straps_band_brown_base_color-u4grtp.png",
      logo: "./3Ddressing/hardware_gold_base_color-rufdgp.jpg",
      // ...数据部分保持不变
      loadingProgress: 0,
      strapsBandColors: [
        {
          value: "./3Ddressing/straps_band_black_base_color-keuqt.jpg",
          label: "黑色带子",
        },
        {
          value: "./3Ddressing/straps_band_brown_base_color-u4grtp.png",
          label: "棕色带子",
        },
        {
          value: "./3Ddressing/straps_band_red_base_color-gl499.jpg",
          label: "红色带子",
        },
        {
          value: "./3Ddressing/straps_band_yellow_base_color-285zpl.jpg",
          label: "黄色带子",
        },
      ],
      logoColors: [
        { value: "./3Ddressing/black-1024-gafxfi.jpg", label: "黑色" },
        { value: "./3Ddressing/gray-1024-cr7rw.png", label: "灰色" },
        {
          value: "./3Ddressing/hardware_gold_base_color-rufdgp.jpg",
          label: "金色硬件",
        },
      ],
      bagBodyColors: [
        {
          value: "./3Ddressing/bag_body_black_base_color-dowtw5.jpg",
          label: "黑色",
        },
        {
          value: "./3Ddressing/bag_body_brown_base_color-b9olzd.png",
          label: "棕色",
        },
        {
          value: "./3Ddressing/bag_body_red_base_color-0omg4f.jpg",
          label: "红色",
        },
        {
          value: "./3Ddressing/bag_body_yellow_base_color-psmu5n.jpg",
          label: "黄色",
        },
      ],
    };
  },
  mounted() {
    this.initThreeJS();
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  watch: {
    // logo(newlogo) {
    //   console.log(newlogo);
    //   if (this.model) {
    //     this.updateLogo(this.model);
    //   }
    // },
    // straps_band(newstraps) {
    //   console.log(newstraps);
    //   if (this.model) {
    //     this.updateStraps_band(this.model);
    //   }
    // },
    // state(newstate) {
    //   if (this.model) {
    //     this.updateOpenstate(this.model);
    //   }
    // },
    // bag_body(newTexture) {
    //   console.log(newTexture);
    //   if (this.model) {
    //     this.updateMaterialTexture(this.model);
    //   }
    // },
  },

  methods: {
    updateBagBody(newVal) {
      this.bag_body = newVal;
    },
    // 窗口大小变化
    onWindowResize() {
      const canvas = this.$refs.modelCanvas;
      // 首先，获取渲染器容器的DOM元素
      var canvasbox = document.getElementById("canvasbox");

      // 然后，获取容器的宽度和高度
      // 注意：clientWidth和clientHeight不包括滚动条、边框和外边距
      var width = canvasbox.clientWidth;
      var height = canvasbox.clientHeight * 0.95;
      console.log(width, height);
      if (canvas && width && height && renderer) {
        renderer.setSize(width, height);

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
      }
    },
    initThreeJS() {
      const rgbeLoader = new RGBELoader();

      // 首先，获取渲染器容器的DOM元素
      var canvasbox = document.getElementById("canvasbox");

      // 然后，获取容器的宽度和高度
      // 注意：clientWidth和clientHeight不包括滚动条、边框和外边距
      var canvasboxWidth = canvasbox.clientWidth;
      var canvasboxHeight = canvasbox.clientHeight * 0.95;
      this.camera = new THREE.PerspectiveCamera(
        75,
        canvasboxWidth / canvasboxHeight,
        0.1,
        1000
      );
      renderer = new THREE.WebGLRenderer({
        canvas: this.$refs.modelCanvas,
        alpha: true,
        // antialias: true
      });

      renderer.setSize(canvasboxWidth, canvasboxHeight);
      // renderer.outputEncoding = THREE.sRGBEncoding; // 支持HDR
      renderer.setClearColor(0xffffff, 0); // 设置背景颜色为白色
      // //监听窗口大小
      // this.onWindowResize();
      // 添加环境光，提供整体的照明
      // const ambientLight = new THREE.AmbientLight(0xffffff, 0.8); // 柔和的白色光

      // 添加方向光，模拟太阳光
      // const directionalLight = new THREE.DirectionalLight(0xffffff, 1); // 强度为1的白色方向光

      const loader = new GLTFLoader();
      // 添加OrbitControls以控制视角
      const controls = new OrbitControls(this.camera, renderer.domElement);
      controls.enableDamping = true; // 启用阻尼效果（惯性）
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = false;
      controls.minDistance = 2; // 限制最小距离
      controls.maxDistance = 30; // 限制最大距离
      loader.load(
        "./3Ddressing/leatherbag_1_.glb",
        // "https://angle-3d-demo.myshopify.com/cdn/shop/3d/models/o/644a80be463b6596/purse.glb",
        (gltf) => {
          this.model = gltf.scene;
          // 放大模型
          this.model.scale.set(4, 4, 4);
          // 计算模型的边界框
          const box = new THREE.Box3().setFromObject(this.model);
          // 加载HDR环境贴图
          rgbeLoader.load("./3Ddressing/luminous.hdr", (texture) => {
            this.envMap = texture;
            console.log(this.envMap);
            this.envMap.mapping = THREE.EquirectangularReflectionMapping;
            this.updateSceneWithEnvMap();
          });
          // 计算模型的中心点
          const center = new THREE.Vector3();
          box.getCenter(center);

          // 将模型中心移到原点
          this.model.position.sub(center);
          // 遍历模型的子对象
          // console.log("模型加载=====================");
          // this.model.traverse((child) => {
          //   if (child.isMesh) {
          //     console.log("名字：" + child.name);
          //   }
          // });
          // console.log("完成=====================");
          scene.add(this.model);
          //初始化包包状态
          this.updateOpenstate();
          // 初始应用纹理
          this.model.traverse((child) => {
            if (child.isMesh) {
              const textureLoader = new THREE.TextureLoader();
              child.material.map = textureLoader.load(this.bag_body);
              child.material.needsUpdate = true;
            }
          });
          // 设置动画混合器
          if (gltf.animations.length > 0) {
            this.mixer = new THREE.AnimationMixer(this.model);
            const action = this.mixer.clipAction(gltf.animations[0]);
            action.play();
          }

          // 设置动画混合器
          if (gltf.animations.length > 0) {
            this.mixer = new THREE.AnimationMixer(this.model);
            const action = this.mixer.clipAction(gltf.animations[0]);
            action.play();
          }

          // 设置相机位置
          this.camera.position.z = 3;

          // 渲染循环
          const animate = () => {
            requestAnimationFrame(animate);
            controls.update(); // 更新OrbitControls状态
            renderer.render(scene, this.camera);
            if (this.mixer) this.mixer.update(0.016);
          };
          animate();
        },
        (xhr) => {
          // 加载进度
          this.loadingProgress = Math.round((xhr.loaded / xhr.total) * 100);
          this.progressWidth = this.loadingProgress;
        },
        (error) => {
          console.error("GLTF Load Failed:", error);
        }
      );
    },
    updateSceneWithEnvMap() {
      // 应用环境光映射到场景
      if (scene) {
        scene.environment = this.envMap;
        scene.traverse((child) => {
          if (child.isMesh) {
            child.material.envMap = this.envMap;
            child.material.needsUpdate = true;
          }
        });
      }
    },

    //包包主体颜色变化
    updateMaterialTexture(newVal) {
      if (!this?.model || !newVal) return;
      this.bag_body = newVal;
      this.model.traverse((child) => {
        if (this.state == "open" && child.name === "open_bag_body") {
          child.material.map = new THREE.TextureLoader().load(this.bag_body);
          child.material.needsUpdate = true;
        }
        if (this.state == "closed" && child.name === "closed_bag_body") {
          child.material.map = new THREE.TextureLoader().load(this.bag_body);
          child.material.needsUpdate = true;
        }
      });
    },
    // 包包打开状态
    updateOpenstate(newVal) {
      if (!this?.model ) return;

      this.state = newVal|| "closed";
      this.model.traverse((child) => {
        // 包包关闭状态
        if (this.state == "closed") {
          //开口袋背带
          if (child.name === "open_bag_back_strap") {
            child.visible = false; // 不显示这个部分
          }
          // 开口袋体
          if (child.name === "open_bag_body") {
            child.visible = false; // 不显示这个部分
          }
          // 开袋内部
          if (child.name === "open_bag_interior") {
            child.visible = false; // 不显示这个部分
          }
          //开袋缝线
          if (child.name === "open_bag_stitches") {
            child.visible = false; // 不显示这个部分
          }
          //开袋五金
          if (child.name === "open_bag_hardware") {
            child.visible = false; // 不显示这个部分
          }
          //关闭的背带
          if (child.name === "closed_bag_back_strap") {
            child.visible = true; // 显示这个部分
          }
          // 封闭式袋体
          if (child.name === "closed_bag_body") {
            child.visible = true; // 显示这个部分
          }
          // 闭合的缝合袋线
          if (child.name === "close_bag_stitches") {
            child.visible = true; // 显示这个部分
          }
          //闭袋五金
          if (child.name === "closed_bag_hardware") {
            child.visible = true; // 显示这个部分
          }
        } else {
          //开口袋背带
          if (child.name === "open_bag_back_strap") {
            child.visible = true; // 显示这个部分
          }
          // 开口袋体
          if (child.name === "open_bag_body") {
            child.visible = true; // 显示这个部分
          }
          // 开袋内部
          if (child.name === "open_bag_interior") {
            child.visible = true; // 显示这个部分
          }
          //开袋缝线
          if (child.name === "open_bag_stitches") {
            child.visible = true; // 显示这个部分
          }
          //开袋五金
          if (child.name === "open_bag_hardware") {
            child.visible = true; // 不显示这个部分
          }
          //关闭的背带
          if (child.name === "closed_bag_back_strap") {
            child.visible = false; // 不显示这个部分
          }
          // 封闭式袋体
          if (child.name === "closed_bag_body") {
            child.visible = false; // 不显示这个部分
          }
          // 闭合的缝合袋线
          if (child.name === "close_bag_stitches") {
            child.visible = false; // 不显示这个部分
          }
          //闭袋五金
          if (child.name === "closed_bag_hardware") {
            child.visible = false;
          }
        }
      });
    },
    //包包 背带 颜色变化
    updateStraps_band(newVal) {
      if (!this?.model || !newVal) return;
      this.straps_band = newVal;
      this.model.traverse((child) => {
        // straps_band
        if (child.name === "arm_strap_and_middle_band") {
          child.material.map = new THREE.TextureLoader().load(this.straps_band);
          child.material.needsUpdate = true;
        }
      });
    },
    //五金和logo
    updateLogo(newVal) {
      if (!this?.model || !newVal) return;
      this.logo = newVal;
      this.model.traverse((child) => {
        // straps_band
        if (child.name === "logo") {
          child.material.map = new THREE.TextureLoader().load(this.logo);
          child.material.needsUpdate = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/guangyuan.png");
  background-size: 800px auto;
  background-repeat: no-repeat;
  background-position: center;
}
/* 媒体查询 */
@media (max-width: 900px) {
  .options {
    flex-direction: column !important; /* 屏幕宽度小于 900px 时，设置为竖排布局 */
  }
  .option {
  display: flex;
  margin-bottom: 15px;
  align-items:center;
}
}
.options {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;
}

.option {
  display: flex;
  align-items:center;
}

.model-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.progress-bar-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: left;
  background-color: rgba(0, 0, 0, 0.144);
  text-align: center;
  line-height: 20px;
  border-radius: 10px;
  color: rgb(245, 98, 8);
  font-size: 14px;
}

.progress-bar {
  height: 20px;
  background-color: #00ff00;
  border-radius: 10px;
  overflow: hidden;

  position: relative;
}
.divbox {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 8px;
  border: 1px solid #2b4150;
  box-sizing: border-box;
}
.divbox > div {
  padding: 3px 5px;
  /* height: 38px; */
  font-size: 16px;

  color: #2b4150;
}
.select {
  background: #2b4150;
  color: #ffffff !important;
  border: unset !important;
}
</style>