<template>
  <GLBModel />
</template>

<script>
import GLBModel from "./components/GLBModel.vue";
GLBModel;
export default {
  name: "App",
  components: {
    GLBModel,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url("https://3ddemo.xxxyin.cn/3Ddressing/bg.png");
  background-size: 100% 100%;
}
</style>
